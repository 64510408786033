import React from 'react';
import {FaDiscord, FaTelegram} from 'react-icons/fa';
import Header from './components/Header';
import Footer from './components/Footer';

const HomePage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header/>

      <main className="container mx-auto px-8 py-12 flex-grow">
        <section className="text-center">
          <h2 className="text-5xl font-bold mb-8 text-text-blue">Wildism Coins</h2>
          <p className="text-xl mb-10 text-text-blue">Enter the Skyblock marketplace with no fear.</p>

          <div
            className="flex flex-col items-center md:flex-row justify-center space-y-4 md:space-y-0 space-x-0 md:space-x-4 mb-6">
            <a href="https://discord.gg/PAGjYUkhkD" target="_blank" rel="noopener noreferrer">
              <button
                className="w-full md:w-auto outline-secondary bg-accent hover:bg-primary text-white py-3 px-6 rounded-full text-lg flex items-center justify-center md:justify-start transform hover:scale-105 transition-all transition-colors transition-transform duration-300 shadow-lg">
                <FaDiscord className="mr-2"/>
                Join our Discord
              </button>
            </a>

            <a href="https://t.me/Wildismm" target="_blank" rel="noopener noreferrer">
              <button
                className="w-full md:w-auto bg-secondary hover:bg-primary text-white py-3 px-6 rounded-full text-lg flex items-center justify-center md:justify-start transform hover:scale-105 transition-all transition-colors transition-transform duration-300 shadow-lg">
                <FaTelegram className="mr-2"/>
                Contact on Telegram
              </button>
            </a>

            <a href="https://discord.gg/scammerlist" target="_blank" rel="noopener noreferrer">
              <button
                className="w-full md:w-auto bg-secondary hover:bg-primary text-white py-3 px-6 rounded-full text-lg flex items-center justify-center md:justify-start transform hover:scale-105 transition-all transition-colors transition-transform duration-300 shadow-lg">
                <FaDiscord className="mr-2"/>
                Join Scammer List
              </button>
            </a>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-10">
            <a href="/buy"
               className="bg-secondary text-text rounded-lg p-6 flex flex-col items-center justify-between hover:bg-accent transform hover:scale-105 transition-colors transition-transform duration-300 shadow-lg">
              <div>
                <h3 className="text-2xl font-bold mb-4">Buy Coins</h3>
                <p className="text-lg">Find and purchase coins from our trusted sellers.</p>
              </div>
            </a>

            <a href="/sell"
               className="bg-secondary text-text rounded-lg p-6 flex flex-col items-center justify-between hover:bg-accent transform hover:scale-105 transition-colors transition-transform duration-300 shadow-lg">
              <div>
                <h3 className="text-2xl font-bold mb-4">Sell Coins</h3>
                <p className="text-lg">Sell your coins securely to our trusted buyers.</p>
              </div>
            </a>

            <a href="/accounts"
               className="bg-secondary text-text rounded-lg p-6 flex flex-col items-center justify-between hover:bg-accent transform hover:scale-105 transition-colors transition-transform duration-300 shadow-lg">
              <div>
                <h3 className="text-2xl font-bold mb-4">Skyblock Accounts</h3>
                <p className="text-lg">Buy and sell Skyblock accounts safely.</p>
              </div>
            </a>

            <a href="/middleman"
               className="bg-secondary text-text rounded-lg p-6 flex flex-col items-center justify-between hover:bg-accent transform hover:scale-105 transition-colors transition-transform duration-300 shadow-lg">
              <div>
                <h3 className="text-2xl font-bold mb-4">Request a Middleman</h3>
                <p className="text-lg">Use our middleman service for secure transactions.</p>
              </div>
            </a>

            <a href="/boosting"
               className="bg-secondary text-text rounded-lg p-6 flex flex-col items-center justify-between hover:bg-accent transform hover:scale-105 transition-colors transition-transform duration-300 shadow-lg">
              <div>
                <h3 className="text-2xl font-bold mb-4">Skyblock Boosting</h3>
                <p className="text-lg">Boost your Skyblock experience with our services.</p>
              </div>
            </a>

            <a href="/scammerlist"
               className="bg-secondary text-text rounded-lg p-6 flex flex-col items-center justify-between hover:bg-accent transform hover:scale-105 transition-colors transition-transform duration-300 shadow-lg">
              <div>
                <h3 className="text-2xl font-bold mb-4">Report a Scammer</h3>
                <p className="text-lg">View and report scammers in the Skyblock trading community.</p>
              </div>
            </a>
          </div>
        </section>
      </main>
      <Footer/>
    </div>
  );
};

export default HomePage;
